<template>
    <div class="home_box">
        
        <div class="h_table">
            <div class="h_table_top">
                <div class="t_top_1">
                    <el-button class="order_btn blue_bgc" size="small" icon="el-icon-plus" @click="add_account_dlg=true">{{$t('main.text10')}}</el-button>
                </div>
                <div class="t_top_Right">
                     <div class="t_top_3">
                        <el-input :placeholder="$t('main.text11')" v-model="form.search_content"></el-input>
                        <i class="el-icon-search"></i>
                     </div>
                     <div>
                        <el-button plain class="search_btn" @click="search">{{$t('main.text12')}}</el-button>
                        <el-button plain class="reset_btn" @click="reset">{{$t('main.text13')}}</el-button>
                     </div>
                </div>
            </div>
            <el-table
            :data="tableData"
            :cell-style="{textAlign:'center',color:'#333'}"
            :header-cell-style="{textAlign:'center',background:'#ECF3FD',color:'#838383'}"
            style="width: 100%">
                <el-table-column :label="$t('main.text70')"
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column :label="$t('main.text15')">
                    <span slot-scope="props">
                        {{ props.row.name||"-" }}
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text16')">
                    <span slot-scope="props">
                        {{ props.row.email }}
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text17')">
                    <span slot-scope="props">
                        <div v-show="!props.row.is_edit">{{ props.row.company_name }}</div>
                        <div v-show="props.row.is_edit">
                            <el-input class="form_my_inp" v-model="props.row.company_name"></el-input>
                        </div>
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text18')">
                    <span slot-scope="props">
                        <div v-show="!props.row.is_edit">{{ props.row.mobile }}</div>
                        <div v-show="props.row.is_edit">
                            <el-input class="form_my_inp" v-model="props.row.mobile"></el-input>
                        </div>
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text19')">
                    <span slot-scope="props">
                        {{ props.row.countOrders }}
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text20')">
                    <span slot-scope="props">
                        {{ props.row.countProcessingOrders }}
                    </span>
                </el-table-column>
                <el-table-column :label="$t('main.text21')" min-width="140">
                    <span slot-scope="props">
                        <!-- status Number Optional 10正常⽤户 20冻结 -->
                        <template v-if="!props.row.is_edit">
                            <span class="type3_c" v-if="props.row.status==10" @click="open_block_up_dlg(props.row.userid)">{{$t('main.text22')}}</span>
                            <span class="type1_c" v-else-if="props.row.status==20" @click="open_enable_dlg(props.row.userid)">{{$t('main.text23')}}</span>
                            <span> | </span>
                        </template>
                        <span class="details" v-show="!props.row.is_edit" @click="props.row.is_edit=true">{{$t('main.text24')}}</span>
                        <span class="details" v-show="props.row.is_edit">
                            <span @click="edit_complete(props.row)">{{$t('main.text25')}}</span>
                            <span> | </span>
                            <span @click="reset">{{$t('main.text26')}}</span>
                        </span>
                        <template v-if="!props.row.is_edit">
                            <span> | </span>
                            <span class="details" @click="open_reset_pwd_dlg(props.row)">{{$t('main.text27')}}</span>
                        </template>
                    </span>
                </el-table-column>

            </el-table>

            <el-pagination
                class="pagination_box"
                v-if="total"
                background
                @size-change="size_change"
                @current-change="current_change"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>

        </div>

        <!-- 创建账户 弹框 -->
        <el-dialog
            :title="$t('main.text28')"
            :visible.sync="add_account_dlg"
            width="600px"
            top="15%"
            center>
            <div class="dlg_main">
                <el-form label-position="right" label-width="auto">
                    <el-form-item :label="$t('main.text29')">
                        <el-input v-model.trim="add_form.email" :placeholder="$t('main.text30')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('main.text31')">
                        <el-input v-model.trim="add_form.company_name" :placeholder="$t('main.text32')"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('main.text33')">
                        <el-input v-model.trim="add_form.mobile" :placeholder="$t('main.text34')"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="登录密码">
                        <el-input v-model.trim="add_form.password" type="password" show-password placeholder="请输入登录密码"></el-input>
                    </el-form-item> -->
                    <el-form-item :label="$t('main.text35')">
                        <el-input v-model.trim="add_form.name" :placeholder="$t('main.text36')"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="add_account_dlg_confirm" class="confirm_btn">{{$t('main.text37')}}</el-button>
                <el-button @click="add_account_dlg = false" class="cancel_btn">{{$t('main.text26')}}</el-button>
            </span>
        </el-dialog>

        <!-- 启用 弹框 -->
        <el-dialog
            title=" "
            :visible.sync="enable_dlg"
            width="500px"
            top="20%"
            center>
            <div class="dlg_body">{{$t('main.text38')}}</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="enable_dlg_confirm" class="confirm_btn">{{$t('main.text39')}}</el-button>
                <el-button @click="enable_dlg = false" class="cancel_btn">{{$t('main.text26')}}</el-button>
            </span>
        </el-dialog>

        <!-- 停用 弹框 -->
        <el-dialog
            title=" "
            :visible.sync="block_up_dlg"
            width="500px"
            top="20%"
            center>
            <div class="dlg_body">{{$t('main.text40')}}</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="block_up_dlg_confirm" class="confirm_btn">{{$t('main.text39')}}</el-button>
                <el-button @click="block_up_dlg = false" class="cancel_btn">{{$t('main.text26')}}</el-button>
            </span>
        </el-dialog>

        <!-- 重置密码 弹框 new 发邮件 -->
        <el-dialog
            :title="$t('main.text27')"
            :visible.sync="reset_pwd_dlg"
            width="600px"
            top="20%"
            center>
            <div class="dlg_main">
                {{$t('main.text41')}}
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="reset_pwd_confirm" class="confirm_btn">{{$t('main.text4')}}</el-button>
                <el-button @click="reset_pwd_dlg = false" class="cancel_btn">{{$t('main.text5')}}</el-button>
            </span>
        </el-dialog>

    </div>
</template>
    
<script>
import {list,create,edit,adminResetLoginPwd} from '@/api/api';
import * as EmailValidator from 'email-validator';
// import md5 from 'js-md5';
export default{
    data() {
        return {
            currentPage: 1, //选中的页数
            page: 1, //分页 从 1 开始
            limit: 10, //每页条数
            total: 0, //一共多少条
            tableData: [],
            form:{
                search_content:"",
            },
            add_account_dlg:false,
            add_form:{
                email:"",
                company_name:"",
                mobile:"",
                password:"",
                name:"",
            },
            reset_form:{
                email:"",
                company_name:"",
                mobile:"",
                password:"",
            },
            enable_dlg:false,
            block_up_dlg:false,
            reset_pwd_dlg:false,
            userid:"",

        }
    },
    mounted() {
        this.get_list();
    },
    methods: {
        current_change(v) {
            this.page = v;
            this.limit = 10;
            this.get_list();
        },
        size_change(v) {
            this.limit = v;
            this.get_list();
        },
        async get_list(){
            const res = await list({
                page:this.page,
                limit:this.limit,
                search_content:this.form.search_content,
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            // this.tableData = res.data.data.list;
            let d = res.data.data.list;
            for (const item of d) {
                if(! ("is_edit" in item)){
                    item.is_edit = false;
                }
            }
            this.tableData = d;

            this.total = res.data.data.countAll;
        },
        search(){
            this.page=1;
            this.limit=10;
            this.currentPage = 1;
            this.get_list();
        },
        reset(){
            this.page=1;
            this.limit=10;
            this.currentPage = 1;
            this.form.search_content="";
            this.get_list();
        },
        async add_account_dlg_confirm(){
            if(!this.add_form.email){
                return this.msg("error",this.$t('main.text30'));
            }
            if(!EmailValidator.validate(this.add_form.email)){
                return this.msg("error",this.$t('main.text42'));
            }
            if(!this.add_form.company_name){
                return this.msg("error",this.$t('main.text32'));
            }
            if(!this.add_form.mobile){
                return this.msg("error",this.$t('main.text34'));
            }
            // if(!this.add_form.password){
            //     return this.msg("error","请输入登录密码");
            // }
            // // 正则表达式：字母+数字，长度6-12
            // const regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9(@#$%^&+=~!?_)]{6,18}$/;
            // if(!regex.test(this.add_form.password)){
            //     return this.msg("error","密码格式错误");
            // }
            if(!this.add_form.name){
                return this.msg("error",this.$t('main.text36'));
            }
            const res = await create({
                email:this.add_form.email,
                company_name:this.add_form.company_name,
                country_code:"86",
                mobile:this.add_form.mobile,
                // password:md5(this.add_form.password),
                name:this.add_form.name
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.msg("success",this.$t('main.text43'));
            this.add_account_dlg=false;
            this.get_list();

        },
        async edit_complete(row){
            if(!row.mobile){
                return false;
            }
            const res = await edit({
                userid:row.userid,
                mobile:row.mobile,
                company_name:row.company_name,
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.msg("success",this.$t('main.text44'));
            this.get_list();
        },
        open_block_up_dlg(userid){
            this.userid = userid;
            this.block_up_dlg = true;
        },
        async block_up_dlg_confirm(){
            const res = await edit({
                userid:this.userid,
                status:"20"
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.msg("success",this.$t('main.text45'));
            this.get_list();
            this.block_up_dlg = false;
        },
        open_enable_dlg(userid){
            this.userid = userid;
            this.enable_dlg = true;
        },
        async enable_dlg_confirm(){
            const res = await edit({
                userid:this.userid,
                status:"10"
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.msg("success",this.$t('main.text45'));
            this.get_list();
            this.enable_dlg = false;
        },
        open_reset_pwd_dlg(row){
            this.userid = row.userid;
            this.reset_form={
                email:row.email,
                company_name:row.company_name,
                mobile:row.mobile,
                password:""
            };
            this.reset_pwd_dlg = true;
        },
        async reset_pwd_dlg_confirm(){
            if(!this.reset_form.email){
                return this.msg("error",this.$t('main.text30'));
            }
            if(!EmailValidator.validate(this.reset_form.email)){
                return this.msg("error",this.$t('main.text163'));
            }
            if(!this.reset_form.company_name){
                return this.msg("error",this.$t('main.text32'));
            }
            if(!this.reset_form.mobile){
                return this.msg("error",this.$t('main.text34'));
            }
            // if(!this.reset_form.password){
            //     return this.msg("error","请输入密码");
            // }
            // // 正则表达式：字母+数字，长度6-12
            // const regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9(@#$%^&+=~!?_)]{6,18}$/;
            // if(!regex.test(this.reset_form.password)){
            //     return this.msg("error","密码格式错误");
            // }

            const res = await edit({
                userid:this.userid,
                email:this.reset_form.email,
                company_name:this.reset_form.company_name,
                mobile:this.reset_form.mobile,
                // password:md5(this.reset_form.password),
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.msg("success",this.$t('main.text122'));
            this.reset_pwd_dlg = false;
            this.get_list();
        },
        async reset_pwd_confirm(){
            const res = await adminResetLoginPwd({
                userid:this.userid,
            });
            if(res.data.code!=200){
                return this.msg("error",res.data.msg);
            }
            this.msg("success",this.$t('main.text46'));
            this.reset_pwd_dlg = false;
            this.get_list();
        }

    },
    watch:{
        add_account_dlg(){
            if(this.add_account_dlg){
                this.add_form={
                    email:"",
                    company_name:"",
                    mobile:"",
                    password:"",
                    name:"",
                };
            }
        }
    }

}
</script>
    
<style scoped>
.h_table{
    border-radius: 6px;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}
.h_table_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    font-size: 16px;
    margin-bottom: 12px;
}
.t_top_Right>div{
    display: inline-block;
}
.t_top_2{
    max-width: 160px;
    margin-right: 20px;
}
.t_top_2 >>> .el-input__inner,
.t_top_3 >>> .el-input__inner{
    border-color: #A9A9A9;
    color: #BCBCBC;
}
.t_top_3{
    max-width: 200px;
    margin-right: 30px;
    position: relative;
}
.t_top_3 i{
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #BCBCBC;
    font-size: 20px;
}
.t_top_3 >>> .el-input__inner{
    text-indent: 2em;
}
.t_top_3 >>> .el-input.is-active .el-input__inner,
.t_top_3 >>> .el-input__inner:focus {
    border-color: #409EFF;
}
.dlg_main{
    margin: auto;
    width: 350px;
}
.dlg_main >>> .el-form-item__content {
    max-width: 280px;
}
.dlg_main >>> .el-form-item__label{
    color: #838383;
}
.dlg_main >>> .el-select {
    width: 100%;
}

</style>
